<template>
    <div>
        <MODAL
            :width="600"
            :visible="visible"
            :class="namespaceClass('contact-support-modal')"
            :skin="skin"
            :title="initedLabels.webToCaseTitle"
        >
            <button
                slot="header-actions"
                class="mds-button mds-button--icon-only"
                @click="close"
            >
                <svg class="mds-icon mds-button__icon mds-button__icon--left">
                    <use :xlink:href="mdsIcon('remove')">
                        <title>Close</title>
                    </use>
                </svg>
            </button>
            <div
                :class="namespaceClass('contact-support-container')"
                slot="content"
            >
                <section
                    :class="namespaceClass('contact-support-form-caption')"
                >
                    {{ initedLabels.webToCaseCaption }}
                </section>
                <markets-ui-form
                    class="mds-form mds-form--small-layout"
                    autocomplete="off"
                >
                    <div class="mds-form__field-group">
                        <markets-ui-input
                            size="small"
                            type="text"
                            :label="initedLabels.fullName"
                            required
                            autocomplete="off"
                            @focus="clearError('fullNameError')"
                            v-model="fullName"
                            :skin="skin"
                            :error="!!fullNameError"
                            :error-text="[fullNameError]"
                        />
                    </div>
                    <div class="mds-form__field-group">
                        <markets-ui-input
                            type="text"
                            autocomplete="off"
                            v-model="email"
                            @focus="clearError('emailError')"
                            :skin="skin"
                            :label="initedLabels.email"
                            required
                            :error="!!emailError"
                            :error-text="[emailError]"
                        />
                    </div>
                    <div
                        class="mds-form__field-group mds-form__field-group--horizontal"
                    >
                        <div class="mds-form__field-group">
                            <markets-ui-input
                                :class="namespaceClass('input__company')"
                                :label="initedLabels.company"
                                type="text"
                                autocomplete="off"
                                v-model="company"
                                @focus="clearError('companyError')"
                                :skin="skin"
                                required
                                :error="!!companyError"
                                :error-text="[companyError]"
                            />
                        </div>
                        <div class="mds-form__field-group">
                            <markets-ui-combo-box
                                :label="initedLabels.component"
                                :hidden-label="false"
                                required
                                size="small"
                                :skin="skin"
                                :class="namespaceClass('combox__component')"
                                :data-model="components"
                                @change="changeComponent"
                            >
                            </markets-ui-combo-box>
                        </div>
                    </div>
                    <div
                        class="mds-form__field-group mds-form__field-group--horizontal"
                    >
                        <div class="mds-form__field-group">
                            <markets-ui-input
                                :class="namespaceClass('input__subject')"
                                type="text"
                                :label="initedLabels.subject"
                                required
                                autocomplete="off"
                                :skin="skin"
                                v-model="subject"
                                @focus="clearError('subjectError')"
                                :error="!!subjectError"
                                :error-text="[subjectError]"
                            />
                        </div>
                        <div class="mds-form__field-group">
                            <markets-ui-combo-box
                                :label="initedLabels.country"
                                :hidden-label="false"
                                required
                                size="small"
                                :skin="skin"
                                :class="namespaceClass('combox__country')"
                                :data-model="countryModel"
                                @change="changeCountry"
                            >
                            </markets-ui-combo-box>
                        </div>
                    </div>
                    <div class="mds-form__field-group">
                        <label class="mds-form__label mds-form__label--small">
                            {{ initedLabels.message }}
                            <abbr
                                class="mds-form__label-required-indicator"
                                title="This field is required."
                                aria-label="This field is required."
                                >*</abbr
                            >
                        </label>
                        <textarea
                            :class="[
                                'mds-form__textarea',
                                namespaceClass('contact-support-area')
                            ]"
                            v-model="description"
                            @focus="clearError('descriptionError')"
                            :placeholder="initedLabels.webToCaseMessageHint"
                        ></textarea>
                        <span
                            v-if="descriptionError"
                            :class="[
                                'mds-form__field-error',
                                'mds-form__field-error--small',
                                namespaceClass(
                                    'contact-support-description-error'
                                )
                            ]"
                            id="input-01-error"
                            role="alert"
                        >
                            <span class="mds-form__field-error-text">
                                {{ descriptionError }}
                            </span>
                        </span>
                    </div>
                    <div :class="namespaceClass('sumbmit-container')">
                        <button
                            class="mds-button mds-button--primary"
                            type="button"
                            @click="verifyForm"
                        >
                            {{ initedLabels.send }}
                        </button>
                    </div>
                </markets-ui-form>
            </div>
        </MODAL>
        <markets-ui-dialog
            :class="namespaceClass('confirm-dialog')"
            @click="dialogResponsed"
            :resolve="confirmDialog.resolve"
            :visible="confirmDialog.visible"
            :skin="skin"
            action-required
            message=""
        >
            <div :class="namespaceClass('confirm-dialog-content')">
                <svg
                    v-if="confirmDialog.showIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 72 72"
                    width="105"
                    :class="namespaceClass('svg--envelope')"
                >
                    <title>envelope</title>
                    <path
                        :class="namespaceClass('dialog-svg-path')"
                        d="M63,30,36,48,9,30M45,18l-9-6-9,6m-9.0033,6.0022L9,30V60H63V30l-9-6m0,12V18H18V36"
                    />
                    <path
                        class="cls-2"
                        style="fill: #0fb300"
                        d="M34.9129,38.1136l-1.194-1.1919.0005-.0009L30.086,33.2886,31.27,32.1044l3.4122,3.4121L40.5334,27l1.3806.9485-.0677.0986Z"
                    />
                </svg>

                <label
                    :class="['mds-form__label', namespaceClass('dialog-title')]"
                >
                    {{ confirmDialog.title }}</label
                >
                <label
                    :class="[
                        'mds-form__label',
                        'mds-form__label--small',
                        namespaceClass('dialog-message')
                    ]"
                    >{{ confirmDialog.message }}</label
                >
            </div>
        </markets-ui-dialog>
    </div>
</template>
<script>
import MODAL from '../ui/modal';
import labels from '../../assets/metadata/label.json';
import { mapGetters, mapActions } from 'vuex';
import { upperCaseFirstLetter } from '../../util';
import componentModules, { getModuleById } from '../../data/component-module';
export default {
    name: 'ContactSupportForm',
    components: { MODAL },
    data() {
        return {
            visible: true,
            fullName: '',
            email: '',
            company: '',
            component: 'none',
            subject: '',
            country: 'United States',
            description: '',
            fullNameError: '',
            emailError: '',
            companyError: '',
            componentError: '',
            subjectError: '',
            descriptionError: '',
            confirmDialog: {
                visible: false,
                showIcon: true,
                message: '',
                resolve: {
                    label: 'OK'
                }
            }
        };
    },
    computed: {
        ...mapGetters('settings', ['skin']),
        ...mapGetters('account', ['token']),
        initedLabels() {
            return labels.en;
        },
        components() {
            return componentModules.map(m => {
                return {
                    id: m.id,
                    text: m.text,
                    name: m.text,
                    selected: this.component === m.id
                };
            });
        },
        countryModel() {
            return ['United States', 'Canada'].map(item => {
                return {
                    id: item,
                    text: item,
                    name: item,
                    selected: this.country === item
                };
            });
        }
    },
    created() {
        this.$eventBus.$on(this.$EVENTS.SHOW_CONTACT_SUPPORT, () => {
            this.visible = true;
        });
    },
    methods: {
        ...mapActions('application', ['submitSupportContactForm']),
        close() {
            this.visible = false;
            this.$emit('closed');
        },
        clearError(errorKey) {
            this[errorKey] = '';
        },
        changeComponent(item) {
            this.component = item.id;
        },
        changeCountry(item) {
            this.country = item.id;
        },
        verifyForm() {
            let hasError = false;
            ['fullName', 'email', 'subject', 'company', 'description'].forEach(
                field => {
                    if (this[field] === '') {
                        this[`${field}Error`] = this.initedLabels[
                            `empty${upperCaseFirstLetter(field)}Error`
                        ];
                        hasError = true;
                    } else if (
                        field === 'email' &&
                        !/^([a-zA-Z]|[0-9]|)(\w|-|\.)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(
                            this.email
                        )
                    ) {
                        this.emailError = this.initedLabels.invalidEmailError;
                        hasError = true;
                    }
                }
            );
            if (!hasError) {
                this.submit();
            }
        },
        submit() {
            const submitFailed = () => {
                this.hide();
                this.confirmDialog.visible = true;
                this.confirmDialog.message = this.initedLabels.failedSubmitMsg;
                this.confirmDialog.title = '';
                this.confirmDialog.showIcon = false;
            };
            this.submitSupportContactForm({
                name: this.fullName,
                email: this.email,
                subject: this.subject,
                description: this.description,
                company: this.company,
                country: this.country,
                component: getModuleById(this.component)
            })
                .then(res => {
                    if (res && res.status && res.status.errorCode === '0') {
                        this.hide();
                        this.confirmDialog.visible = true;
                        this.confirmDialog.message = this.initedLabels.successSubmitMsg;
                        this.confirmDialog.title = this.initedLabels.successSubmitTitle;
                    } else {
                        submitFailed();
                    }
                })
                .catch(e => {
                    submitFailed();
                });
        },
        dialogResponsed() {
            this.confirmDialog.visible = false;
        },
        hide() {
            this.visible = false;
            this.subject = '';
            this.description = '';
            this.component = 'none';
        }
    }
};
</script>
