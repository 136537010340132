import utils from '../util';
const componentModules = [
    {
        id: 'none',
        module: 'Market Monitoring',
        text: 'None'
    },
    {
        id: 'alerts',
        module: 'Alerts',
        text: 'Alerts'
    },
    {
        id: 'charts',
        module: 'Charts',
        text: 'Charts'
    },
    {
        id: 'commodities',
        module: 'Commodity Futures',
        text: 'Commodities'
    },
    {
        id: 'cryptocurrency',
        module: 'Cryptocurrency',
        text: 'Cryptocurrency'
    },
    {
        id: 'excelRTD',
        module: 'Excel RTD',
        text: 'Excel RTD'
    },
    {
        id: 'forex',
        module: 'Forex',
        text: 'Forex'
    },
    {
        id: 'gla',
        module: 'Gainers, Losers & Actives',
        text: 'Gainers, Losers & Actives'
    },
    {
        id: 'gmb',
        module: 'Global Market Barometer',
        text: 'Global Market Barometer'
    },
    {
        id: 'investmentNews',
        module: 'News',
        text: 'Investment News'
    },
    {
        id: 'marketIndexesWatchlist',
        module: 'Watchlist',
        text: 'Market Indexes Watchlist'
    },
    {
        id: 'morningstarSectorHeatmap',
        module: 'Sector Heatmap',
        text: 'Morningstar Sector Heatmap'
    },
    {
        id: 'morningstarSectorWatchlist',
        module: 'Watchlist',
        text: 'Morningstar Sector Watchlist'
    },
    {
        id: 'options',
        module: 'Options',
        text: 'Options'
    },
    {
        id: 'other',
        module: 'Market Monitoring',
        text: 'Other'
    },
    {
        id: 'preMarketFutures',
        module: 'Watchlist',
        text: 'Pre-Market Futures'
    },
    {
        id: 'quote',
        module: 'Quote',
        text: 'Quote'
    },
    {
        id: 'report',
        module: 'SAL Quote Page',
        text: 'Report'
    },
    {
        id: 'research',
        module: 'Research',
        text: 'Research'
    },
    {
        id: 'search',
        module: 'Autocomplete/Search',
        text: 'Search'
    },
    {
        id: 'timeSales',
        module: 'Time & Sales',
        text: 'Time & Sales'
    },
    {
        id: 'topStories',
        module: 'News',
        text: 'Top Stories'
    },
    {
        id: 'usGovernmentBondWatchlist',
        module: 'Watchlist',
        text: 'U.S. Government Bond Watchlist'
    },
    {
        id: 'usMarketBarometer',
        module: 'U.S. Market Barometer',
        text: 'U.S. Market Barometer'
    },
    {
        id: 'usMarketNews',
        module: 'News',
        text: 'U.S. Market News'
    },
    {
        id: 'usTreasuryYieldRates',
        module: 'Bond Curve/Rate',
        text: 'U.S. Treasury Yield Rateseport'
    },
    {
        id: 'userAccount',
        module: 'Market Monitoring',
        text: 'User Account'
    },
    {
        id: 'watchlist',
        module: 'Watchlist',
        text: 'Watchlist'
    },
    {
        id: 'portfolio',
        module: 'portfolio',
        text: 'Model Portfolio'
    }
];

export function getModuleById(id) {
    return utils.find(componentModules, item => id === item.id).module;
}
export default componentModules;
